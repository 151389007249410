<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Formulario Eventos Alumnos" collection="eloqua_evento_estudiante" templateTable="TableEventosForm" templateTableModal="TableEventosModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        ChannelType_c: 'Canal',
        TipoDeLead_c: 'Tipo de lead',
        TipoDeOrigen_c: 'Tipo de origen',
        PrimaryContactPersonFirstName: 'Nombre',
        PrimaryContactPersonLastName: 'Apellido paterno',
        ApellidoMaterno_c: 'Apellido materno',
        RUToRUTProvisionalDeContacto_c: 'Número de documento',
        DNIoPasaporte_c: 'DNI o Pasaporte',
        TipoDeCorreo_c: 'Tipo de correo',
        PrimaryContactEmailAddress: 'E-mail',
        FormularioDeOrigen_c: 'Formulario de origen',
        CodigoDEMREDePrograma_c: 'Código DEMRE de programa',
        Name: 'Carrera',
        ZonaDeAdmision_c: 'Zona de admisión',
        PaisDeOrigen_c: 'País de origen',
        Nacionalidad_c: 'Nacionalidad',
        RegionColegio_c: 'Región colegio',
        ComunaColegio_c: 'Comuna colegio',
        RBDoIdentificadorDeInstitucion_c: 'RBD colegio',
        nombreEvento: 'Nombre evento',
        AnioEstimadoDeEgresoTexto_c: 'Año estimado de egreso',
        TipoDeTelefono_c: 'Tipo de teléfono',
        PrimaryPhoneCountryCode: 'Código celular',
        PrimaryPhoneAreaCode: 'Código teléfono',
        PrimaryPhoneNumber: 'Número celular',
        utm_source: 'UTM Source',
        utm_medium: 'UTM Medium',
        utm_campaign: 'UTM Campaign',
        historial: 'Historial',
      },
      query: {
        collection: 'eloqua_evento_estudiante',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>